import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { urls } from 'src/app/utils/url-utils';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ZonesService {

  constructor(private http: HttpClient) { }

  getZones() {
    return this.http.get<any>(environment.BASE_URL +'zones');
     
  }

}
