import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import * as moment from "moment";
import { MainService } from "src/app/services/main-service/main.service";
import { SharedService } from "src/app/services/shared-service/shared-service";
import { SocketService } from "src/app/services/socket-service/socket-service.service";
import { Events } from "src/app/utils/event-utils";

@Component({
  selector: "app-tracking",
  templateUrl: "./tracking.component.html",
  styleUrls: ["./tracking.component.css"],
})
export class TrackingComponent implements OnInit {
  mainObject;
  tripId;
  passengerId;
  isRideInfoOpened = false;
  isContactOpened = false;
  googleFare;

  constructor(
    private sharedService: SharedService,
    private mainService: MainService,
    private socket: SocketService,
    private router: Router
  ) {}

  ngOnInit() {
    const url = this.router.url.split("/");
    if (url.length === 5) {
      this.passengerId = url[2];
      this.tripId = url[4];
      console.log("url -> ", url);
      console.log("url -> ", this.passengerId, this.tripId);
      this.getPassenger();
    }
    this.socket.getSocket().on("connect", (reason) => {
      this.getRideDetails();
    });

    this.sharedService.getRequestRideData().subscribe((data) => {
      this.mainObject = data;
      // if (data === null ) {
      //   // this.sharedService.clearData();
      //   this.router.navigate(["**"]);
      // }
    });

    this.socket.addListener(Events.DRIVER_NOTIFICATIONS).subscribe((data) => {
      console.log(Events.DRIVER_NOTIFICATIONS, data);
      if (data.message) {
        // this.sharedService.clearData();
        this.router.navigate(["**"]);
      } else {
        console.log(Events.DRIVER_NOTIFICATIONS, data);
        if (data.ride && data.ride.driver) {
          this.mainObject.ride.driver = data.ride.driver;
        }

        if (!["farefeed","tolladded"].includes( data.type)) {
        this.mainObject.ride.status = data.ride.status;
        this.sharedService.updateRequestRideData({
          ...this.mainObject,
          isTracking: true,
        });

        if (["hanging", "completed", "finished",].includes(data.ride.status)) {
          // send to expire
          // this.sharedService.clearData();
          this.router.navigate(["**"]);
        }

      }
      }
    });

    this.socket.addListener(Events.GET_RIDE_INFO).subscribe((data) => {
      if (data.message) {
        // this.sharedService.clearData();
        this.router.navigate(["**"]);
      } else {
        if (["hanging", "completed", "finished"].includes(data.ride.status)) {
          // send to expire
          // this.sharedService.clearData();
          this.router.navigate(["**"]);
        }else{
          this.sharedService.updateRequestRideData({
            ...this.mainObject,
            ride: data.ride,
            isTracking: true,
          });
        }
      }
    });

    this.socket.addListener(Events.GOOGLE_ESTIMATION).subscribe((data) => {
      console.log(data);
      if (data && data.distance) {
        this.googleFare = data.distance;
      }
    });
  }

  getPassenger() {
    this.mainService
      .fetchPassengerById(this.passengerId)
      .subscribe((data: any) => {
        if (data.message) {
          this.router.navigate(["**"]);
        } else {
          this.socket.setToken(data.token);
          this.sharedService.updateRequestRideData({
            ...this.mainObject,
            passenger: data.passenger,
            isTracking: true,
          });
        }
      });
  }
  getRideDetails() {
    this.socket.emit(Events.GET_RIDE_INFO, { rideId: this.tripId });
  }



  ngOnDestroy() {}

  goToHome() {
    this.sharedService.clearData();
    this.router.navigate(["**"]);
  }
  openContact() {
    if(this.isRideInfoOpened) this.isRideInfoOpened =false;
    this.isContactOpened = !this.isContactOpened;
  }
  shareTrip(ride) {
    this.sharedService.share(ride);
  }
  fetchGoogleETA() {
    if(this.isContactOpened) this.isContactOpened =false;
    this.isRideInfoOpened = !this.isRideInfoOpened;
    // if (this.googleFare) return;

    if (
      this.mainObject &&
      this.mainObject.ride &&
      this.mainObject.ride.locations[0]
    ) {
      this.socket.emit(Events.GOOGLE_ESTIMATION, {
        pickup: `${this.mainObject.ride.locations[0].latitude},${this.mainObject.ride.locations[0].longitude}`,
        dropOff: `${this.mainObject.ride.locations[1].latitude},${this.mainObject.ride.locations[1].longitude}`,
      });
    } else {
      console.log("Payload null");
    }
  }

  openSMS(number) {
    const a = "Hi ";
    if (navigator.userAgent.match(/Android/i)) {
      window.open("sms://"+number+"/?body=" + a , "_self");
    }

    if(navigator.userAgent.match(/iPhone/i)){
           
      window.open("sms://"+number+"/&body=" + a ,"_self")
       
     }
  }
}
