import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urls } from 'src/app/utils/url-utils';
import { catchError } from 'rxjs/operators';
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: 'root'
})
export class MainService {
  url = urls.BASE_URL;
  constructor(private http: HttpClient) { }

  fetchFleetProfile(payload) {
    // 612f3b04dac7b20ca833ef6a
    return this.http.get(`${environment.BASE_URL}fleets/qrcode/`+payload);
  }

  calculateFareEstimation(payload) {
    return this.http.post(`${environment.BASE_URL}fares/estimation`,payload);
  }

  rideRequest(payload) {
    let authHeader = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let options = { headers: authHeader };
    return this.http.post(`${environment.BASE_URL}assigns/qrcodedispatchcab`,payload,options);
  }

  createPassenger(payload) {
    let authHeader = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let options = { headers: authHeader };
    return this.http.post(`${environment.BASE_URL}passengers`,payload,options);
  }


  fetchJobData(payload) {
    return this.http.get(`${environment.BASE_URL}assigns/qrcodedispatchcab?trackId=`+payload);
  }

  fetchInvoiceData(payload) {
    return this.http.get(`${environment.BASE_URL}assigns/qrcodedispatchcabpaymentinfo?jobId=`+payload);
  }

  getAddressFromLatlng(lat, lng) {
    return this.http.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyAhQKWoOEKCQWCuExvFPTGsnql9Y3-NFBA`);
  }

  fetchPassengerById(payload) {
    return this.http.get(`${environment.BASE_URL}passengers/token/`+payload);
  }
  getVehicles(queryParams) {
    const params = new HttpParams({fromObject: queryParams});
    return this.http.get<any>(urls.BASE_URL + 'vehicles', { params });
     
  }
  fetchReceiptData(queryParams) {
    return this.http.get<any>(urls.BASE_URL + 'rides/company/'+queryParams);
     
  }

  fetchFleet(payload) {
    return this.http.get(`${environment.BASE_URL}fleets/`+payload);
  }
}
