import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MainService } from "src/app/services/main-service/main.service";
import { SharedService } from "src/app/services/shared-service/shared-service";
import * as moment from "moment-timezone";

@Component({
  selector: "app-trip-receipt",
  templateUrl: "./trip-receipt.component.html",
  styleUrls: ["./trip-receipt.component.css"],
})
export class TripReceiptComponent implements OnInit {
  mainObject;
  tripId;
  fleet;
  breakdownTotal;
  adjustmentFare;
  startedTime;
  completedTime;
  rideTime;
  elapsedTime;
  routeMapImage;
  timeZone= 'America/Los_Angeles';

  mapper = {
    'hanging': 'Requested',
    'onway': 'Accepted',
    'arrived': 'OnScene',
    'started': 'Started',
    'completed': 'Arrived',
    'finished': 'Finished',
    'trail': 'Trail'
  }
  driver;
  focalPerson;

  constructor(private mainService: MainService, private router: Router) { }

  getFleetInfo(fleet) {
    this.mainService.fetchFleet(fleet).subscribe((data) => {
      console.log("fleet: ", data);
      if (data) {
        this.fleet = data;
      }
    });
  }
  getReceiptData() {
    this.mainService.fetchReceiptData(this.tripId).subscribe((data) => {
      console.log("getReceiptData: ", data);
      if (data) {
        this.mainObject = data;
        if (this.mainObject.driver && this.mainObject.driver.company) {
          this.timeZone = this.mainObject.driver.company.timeZone;
        }

        const events = this.mainObject.events;
        if (events && events.length) {

          let onway;
          let started;
          let finished;
          events.forEach(evt => {

            if (evt.status === 'started') {
              started = evt;
            }

            if (evt.status === 'onway') {
              onway = evt;
            }

            if (evt.status === 'trail') {
              this.routeMapImage = evt.imageURL;
            }

            if (evt.status === 'finished') {
              finished = evt;
              let startTime = moment(started.createdAt);
              let endTime = moment(finished.createdAt);
              let duration = moment.duration(endTime.diff(startTime));
              // this.rideTime = parseInt(duration.asMinutes()) % 60;
              this.rideTime = duration.hours() + ":" + duration.minutes() + ":" + duration.seconds();
              this.elapsedTime = this.rideTime;
              if (onway) {
                let onwayTime = moment(onway.createdAt);
                let duration = moment.duration(endTime.diff(onwayTime));
                // this.elapsedTime = parseInt(duration.asMinutes()) % 60;
                this.elapsedTime = duration.hours() + ":" + duration.minutes() + ":" + duration.seconds();
              }
              this.routeMapImage = evt.imageURL;
            }
          });

        }

        this.mainObject.scheduleTime = moment(this.mainObject.scheduleTime).tz(this.timeZone).format("LL");
        if (this.mainObject.createdBy === "driver-meter") {
          this.startedTime = moment(this.mainObject.events[0].createdAt).tz(this.timeZone).format("HHmm");
          this.completedTime = moment(this.mainObject.events[1].createdAt).tz(this.timeZone).format("HHmm");
        } else {
          this.startedTime = moment(this.mainObject.events[3].createdAt).tz(this.timeZone).format("HHmm");
          this.completedTime = moment(this.mainObject.events[4].createdAt).tz(this.timeZone).format("HHmm");

        }
        if (this.mainObject.breakdown && this.mainObject.breakdown.length) {
          this.breakdownTotal = this.mainObject.breakdown.shift();
        }

        if (this.mainObject.driver && 
          this.mainObject.driver.company &&
          this.mainObject.driver.company.focalPerson
        ) {
          this.focalPerson = this.mainObject.driver.company.focalPerson;
        }

        this.getFleetInfo(this.mainObject.fleet);
        this.calculateAdjustment();
      }
    });
  }
  ngOnInit() {
    const url = this.router.url.split("/");
    if (url.length === 3) {
      this.tripId = url[2];
      this.getReceiptData();
    }
  }

  calculateAdjustment() {
    const total = (this.breakdownTotal && this.breakdownTotal.value) ? parseFloat(this.breakdownTotal.value) : 0;
    const breakdown = this.mainObject.breakdown;
    let breakdownTotal = 0;
    if (breakdown && breakdown.length) {
      breakdown.forEach(fare => {
        breakdownTotal = breakdownTotal + parseFloat(fare.value)
      });
    }
    this.adjustmentFare = Math.abs(total - breakdownTotal);
    console.log('Adjustment Fare ', this.adjustmentFare);
  }

}
