import { Component, VERSION, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ZXingScannerComponent } from "@zxing/ngx-scanner";
// import { Result } from '@zxing/library';

@Component({
  selector: "app-qrcode-scanner",
  templateUrl: "./qrcode-scanner.component.html",
  styleUrls: ["./qrcode-scanner.component.css"],
})
export class QrcodeScannerComponent implements OnInit {
  @ViewChild("scanner", { static: true, read: false })
  scanner: ZXingScannerComponent;
  hasDevices: boolean;
  hasPermission: boolean;
  qrResultString: string;
  qrResult;
  availableDevices = null;
  currentDevice = null;

  ngVersion = VERSION.full;

  constructor() {}

  ngOnInit() {
    this.scanner.camerasFound.subscribe((devices: MediaDeviceInfo[]) => {
      this.hasDevices = true;
      this.availableDevices = devices;
      this.currentDevice = null;
      if (this.availableDevices.length > 1) {
        const defaultCamera = this.availableDevices.filter(
          (e) => e.label.toLocaleLowerCase().indexOf("back") > -1
        );
        if (defaultCamera !== null && defaultCamera !== undefined) {
          this.currentDevice = defaultCamera[0];
        } else {
          this.currentDevice = this.availableDevices[0];
        }
      } else {
        this.currentDevice = this.availableDevices[0];
      }

      // selects the devices's back camera by default
      // for (const device of devices) {
      //     if (/back|rear|environment/gi.test(device.label)) {
      //         this.scanner.changeDevice(device);
      //         this.currentDevice = device;
      //         break;
      //     }
      // }
    });
    this.scanner.camerasNotFound.subscribe(() => (this.hasDevices = false));
    this.scanner.scanComplete.subscribe(
      (result: any) => (this.qrResult = result)
    );
    this.scanner.permissionResponse.subscribe(
      (perm: boolean) => (this.hasPermission = perm)
    );
  }

  displayCameras(cameras: MediaDeviceInfo[]) {
    console.debug("Devices: ", cameras);
    this.availableDevices = cameras;
  }

  handleQrCodeResult(resultString: string) {
    console.debug("Result: ", resultString);
    this.qrResultString = resultString;
  }

  onDeviceSelectChange(selectedValue) {
    // console.debug('Selection changed: ', selectedValue);
    // this.currentDevice = this.scanner.getDeviceById(selectedValue);

    if (selectedValue !== "null") {
      this.currentDevice = this.availableDevices.find(
        (x) => x.deviceId === selectedValue
      );
    } else {
      this.currentDevice = null;
    }
  }

  stateToEmoji(state: boolean): string {
    const states = {
      // not checked
      undefined: "❔",
      // failed to check
      null: "⭕",
      // success
      true: "✔",
      // can't touch that
      false: "❌",
    };

    return states["" + state];
  }

  bookRide(url) {

    
    window.location.href =url;
  }

  clearData() {
    this.qrResultString = '';
  }
}