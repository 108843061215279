import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { urls } from "src/app/utils/url-utils";
import { SharedService } from "./services/shared-service/shared-service";
import * as moment from "moment-timezone";
import { SocketService } from "./services/socket-service/socket-service.service";
import { Events } from "./utils/event-utils";
import { ToastrService } from "ngx-toastr";
declare var $: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  url = urls.BASE_URL;
  loaderFilter = [this.url + ""];
  isFirstTimeCall = true;
  isfitBounds = false;
  mainObject;
  lat = 32.715736;
  lng = -117.161087;
  pickupMarker;
  dropoffMarker;

  driverMarker;
  public origin: any;
  public destination: any;
  markerOptions = {
    origin: {
      icon: "assets/images/current-location.png",
    },
    destination: {
      icon: "assets/images/destination.png",
    },
  };
  renderOptions = {
    suppressMarkers: true,
  };
  styles = [
    {
      elementType: "geometry",
      stylers: [
        {
          color: "#f5f5f5",
        },
      ],
    },
    {
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161",
        },
      ],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#f5f5f5",
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#bdbdbd",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [
        {
          color: "#e5e5e5",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          color: "#ffffff",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          color: "#dadada",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161",
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [
        {
          color: "#e5e5e5",
        },
      ],
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#c9c9c9",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
  ];
  constructor(
    private sharedService: SharedService,
    private router: Router,
    private socket: SocketService
  ) {}

  ngOnInit() {
    
    this.sharedService.getRequestRideData().subscribe((data) => {
      this.mainObject = data;
      // console.log("AppComponent Data", this.mainObject);
      if (this.mainObject) {
        this.isfitBounds = true;
        if (this.mainObject.passenger) {
          this.socket.connectSocket();
        }

        
        if (this.mainObject.requestRide) {
          if (this.mainObject.requestRide.origin) {
            this.origin = {
              lat: this.mainObject.requestRide.origin.lat,
              lng: this.mainObject.requestRide.origin.lng,
            };
          }

          if (this.mainObject.requestRide.destination) {
            this.destination = {
              lat: this.mainObject.requestRide.destination.lat,
              lng: this.mainObject.requestRide.destination.lng,
            };
          }
        }

        if (this.mainObject.isTaxiStand && this.mainObject.taxiStand) {
          if (this.mainObject.taxiStand.origin) {
            this.pickupMarker = {
              lat: this.mainObject.taxiStand.origin.lat,
              lng: this.mainObject.taxiStand.origin.lng,
            };
          }

          if (this.mainObject.taxiStand.destination) {
            this.dropoffMarker = {
              lat: this.mainObject.taxiStand.destination.lat,
              lng: this.mainObject.taxiStand.destination.lng,
            };
          }
        }

        if (this.mainObject.trip && !this.mainObject.ride) {
          this.origin = {
            lat: this.mainObject.trip.jobOriginLatitude,
            lng: this.mainObject.trip.jobOriginLongitude,
          };
          this.destination = {
            lat: this.mainObject.trip.jobDestinationLatitude,
            lng: this.mainObject.trip.jobDestinationLongitude,
          };
        }

        if (this.mainObject.ride) {
          if (
            !this.origin ||
            this.mainObject.ride.locations[0].latitude !== this.origin.lat
          ) {
            this.origin = {
              lat: this.mainObject.ride.locations[0].latitude,
              lng: this.mainObject.ride.locations[0].longitude,
            };
          }

          if (
            !this.destination ||
            this.mainObject.ride.locations[1].latitude !== this.destination.lat
          ) {
            this.destination = {
              lat: this.mainObject.ride.locations[1].latitude,
              lng: this.mainObject.ride.locations[1].longitude,
            };
          }

          if (this.mainObject.isTracking && this.mainObject.ride) {
            this.router.navigate([
              "tracking/" +
                this.mainObject.ride.passenger +
                "/ride/" +
                this.mainObject.ride._id,
            ]);
          }else if (
            this.isFirstTimeCall &&
            ["hanging","onway", "arrived", "started",].includes(
              this.mainObject.ride.status
            )
          ) {
            this.router.navigate(["enrouteride/" + this.mainObject.ride._id]);
          }else if (
            this.isFirstTimeCall &&
            ["completed", "finished"].includes(this.mainObject.ride.status)
          ) {
            this.router.navigate(["invoice/" + this.mainObject.ride._id]);
          }



          this.isFirstTimeCall = false;
        }
      } else {
        this.socket.disconnectSocket();
        this.destination = null;
        this.origin = null;
        this.driverMarker = null;
        this.pickupMarker = null;
        this.dropoffMarker = null;
      }
    });

    this.socket.addListener(Events.UPDATE_FLEET_LOCATION).subscribe((data) => {
      // console.log(Events.UPDATE_FLEET_LOCATION, data);
      
      if (data.fleetLocation) {
        this.driverMarker = {
          lat: data.fleetLocation.location.coordinates[1],
          lng: data.fleetLocation.location.coordinates[0],
        };
      }
      // console.log(" this.driverMarker",  this.driverMarker);

    });
  }
  changeBounds() {
    this.isfitBounds = !this.isfitBounds;
  }
  connect() {
    this.socket.connectSocket();
  }
  close() {
    this.socket.disconnectSocket();
  }
}
