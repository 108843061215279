import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import * as moment from "moment-timezone";
import { MainService } from "src/app/services/main-service/main.service";
import { SharedService } from "src/app/services/shared-service/shared-service";
import { SocketService } from "src/app/services/socket-service/socket-service.service";
import { Events } from "src/app/utils/event-utils";
@Component({
  selector: "app-request-ride",
  templateUrl: "./request-ride.component.html",
  styleUrls: ["./request-ride.component.css"],
})
export class RequestRideComponent implements OnInit {
  isModalOpened = false;
  mainObject;

  submitted = false;
  userForm: FormGroup;

  constructor(
    private sharedService: SharedService,
    private mainService: MainService,
    private formBuilder: FormBuilder,
    private socket: SocketService,
    private router: Router
  ) {}

  ngOnInit() {
    this.socket.addListener(Events.CREATE_RIDE).subscribe((data) => {
      console.log("Trip Response: ", data);

      this.sharedService.updateRequestRideData({
        ...this.mainObject,
        ride: data.ride,
      });
      this.router.navigate(["enrouteride/" + data.ride._id]);
    });
    this.sharedService.getRequestRideData().subscribe((data) => {
      this.mainObject = data;
      if (data === null) {
        // this.sharedService.clearData();
        this.router.navigate(["/"]);
      }
    });

    this.userForm = this.formBuilder.group({
      displayName: ["", Validators.required],
      contactNumber: ["", Validators.required],
      type: ["guest"],
    });
  }

  get form() {
    return this.userForm.controls;
  }

  onSubmit() {
    console.log("form Values", this.userForm.value);
    this.submitted = true;
    if (!this.userForm.invalid) {
      this.mainService
        .createPassenger(this.userForm.value)
        .subscribe((reponse: any) => {
          console.log("Passenger Data ", reponse);
          this.socket.setToken(reponse.token);

          this.sharedService.updateRequestRideData({
            ...this.mainObject,
            passenger: reponse,
          });
          this.createTripRequest();
          
        });
    }
  }

  createTripRequest() {
    const payload = {
      scheduleTime: moment()
        .tz(this.mainObject.fleetProfile.company.timeZone)
        .toISOString(),
      fare: this.mainObject.fare,
      passengers: this.mainObject.trip.jobPassengerNumber,
      locations: [
        {
          address: this.mainObject.trip.jobOriginAddress,
          latitude: this.mainObject.trip.jobOriginLatitude,
          longitude: this.mainObject.trip.jobOriginLongitude,
        },
        {
          address: this.mainObject.trip.jobDestinationAddress,
          latitude: this.mainObject.trip.jobDestinationLatitude,
          longitude: this.mainObject.trip.jobDestinationLongitude,
        },
      ],
      vehicle: this.mainObject.fleetProfile.vehicle._id,
      requestedFleet: this.mainObject.fleetProfile._id,
      latitude: this.mainObject.trip.jobOriginLatitude,
      longitude: this.mainObject.trip.jobOriginLongitude,
      userType: "passenger",
    };
    console.log("response payload ", payload);
    this.socket.emit(Events.CREATE_RIDE, payload);
  }

  onRequestRide() {
    this.isModalOpened = true;
  }
  onCancelModal() {
    this.isModalOpened = false;
  }
}
