import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { error } from "protractor";
import { MainService } from "src/app/services/main-service/main.service";
import { SharedService } from "src/app/services/shared-service/shared-service";
import { SocketService } from "src/app/services/socket-service/socket-service.service";
import { Events } from "src/app/utils/event-utils";
import * as moment from "moment-timezone";
import { environment } from "src/environments/environment";
import * as _ from "lodash";
import { ZonesService } from "src/app/services/zones-service/zones.service";
declare var google: any;
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  submitted = false;
  requestForm: FormGroup;
  fleetProfile;
  mainObject;
  fleetId;
  fareResponce;
  isModalOpened = false;
  timeZone = "America/New_York";
  vehicles;
  selectedVehicle;

  userFormSubmitted = false;
  isConnecting = false;
  userForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private mainService: MainService,
    private router: Router,
    private sharedService: SharedService,
    private socket: SocketService,
    private zonesService: ZonesService,
  ) { }

  ngOnInit() {
    this.socket.addListener(Events.CREATE_RIDE).subscribe((data) => {
      console.log("Trip Response: ", data);
      if (data.message) {
        this.mainObject = {
          ...this.mainObject,
          ride: { ...this.mainObject.ride, message: data.message },
        };
        this.sharedService.updateRequestRideData(this.mainObject);

      } else {
        this.mainObject = {
          ...this.mainObject,
          ride: data.ride,
        };
        this.sharedService.updateRequestRideData(this.mainObject);
        this.router.navigate(["enrouteride/" + data.ride._id]);
      }

    });

    this.requestForm = this.formBuilder.group({
      jobOriginAddress: ["", Validators.required],
      jobOriginLatitude: [null, Validators.required],
      jobOriginLongitude: [null, Validators.required],
      jobDestinationAddress: ["", Validators.required],
      jobDestinationLatitude: [null, Validators.required],
      jobDestinationLongitude: [null, Validators.required],
      jobPassengerNumber: [1, Validators.required],
    });

    this.userForm = this.formBuilder.group({
      displayName: ["", Validators.required],
      contactNumber: ["", Validators.required],
      type: ["guest"],
    });

    this.sharedService.getPosition().then((pos) => {
      console.log(`Positon: ${pos.lng} ${pos.lat}`);
      this.mainService
        .getAddressFromLatlng(pos.lat, pos.lng)
        .subscribe((data: any) => {
          // console.log("Geocode", data);
          if (
            data &&
            data.status === "OK" &&
            data.results &&
            data.results.length
          ) {
            this.requestForm.patchValue({
              jobOriginAddress: data.results[0].formatted_address,
              jobOriginLatitude: data.results[0].geometry.location.lat,
              jobOriginLongitude: data.results[0].geometry.location.lng,
            });
          } else {
            console.log("error in  location geocoding");
          }
        });
    });

    this.sharedService.getRequestRideData().subscribe((data) => {
      this.mainObject = data;
      console.log("HomeComponent Data", this.mainObject);

      // this.requestForm.patchValue({
      //   jobOriginAddress: this.mainObject.trip.jobOriginAddress,
      //   jobOriginLatitude: this.mainObject.trip.jobOriginLatitude,
      //   jobOriginLongitude: this.mainObject.trip.jobOriginLongitude,
      //   jobDestinationAddress: this.mainObject.trip.jobDestinationAddress,
      //   jobDestinationLatitude: this.mainObject.trip.jobDestinationLatitude,
      //   jobDestinationLongitude: this.mainObject.trip.jobDestinationLongitude,
      //   jobPassengerNumber: this.mainObject.trip.jobPassengerNumber,
      // });
    });

    const url = this.router.url.split("/");
    console.log("url", url);
    if (url.length === 3) {
      this.fleetId = url[2];
      this.getFleetProfile();
    } else {
      this.getVehicles();
    }
  }

  getFleetProfile() {
    this.mainService.fetchFleetProfile(this.fleetId).subscribe(
      (reponse: any) => {
        console.log("Fleet Profile ", reponse);

        if (reponse.message) {
          this.router.navigate(["/"]);
        } else {
          this.fleetProfile = reponse;
          this.sharedService.updateRequestRideData({
            ...this.mainObject,
            fleetProfile: this.fleetProfile,
          });
          this.selectedVehicle = this.fleetProfile.vehicle._id
        }
      },
      (error) => {
        this.router.navigate(["/"]);
      }
    );
  }

  get form() {
    return this.requestForm.controls;
  }
  get form1() {
    return this.userForm.controls;
  }
  getOriginAddress(place) {
    if (place.formatted_address) {
      this.requestForm.patchValue({
        jobOriginLatitude: place.geometry.location.lat(),
        jobOriginLongitude: place.geometry.location.lng(),
        jobOriginAddress: place.formatted_address,
      });
    }
    this.getFareEstimation();
  }

  getDestinationAddress(place) {
    if (place.formatted_address) {
      this.requestForm.patchValue({
        jobDestinationLatitude: place.geometry.location.lat(),
        jobDestinationLongitude: place.geometry.location.lng(),
        jobDestinationAddress: place.formatted_address,
      });
    }
    this.getFareEstimation();
  }

  clearOriginAddress() {
    this.requestForm.patchValue({
      jobOriginLatitude: null,
      jobOriginLongitude: null,
      jobOriginAddress: "",
    });
    this.sharedService.updateRequestRideData(null);
    this.fareResponce = null;
  }

  clearDestinationAddress() {
    this.requestForm.patchValue({
      jobDestinationLatitude: null,
      jobDestinationLongitude: null,
      jobDestinationAddress: "",
    });

    this.sharedService.updateRequestRideData(null);
    this.fareResponce = null;
  }

  getFareEstimation() {
    const formData = this.requestForm.value;
    if (!formData.jobOriginLatitude || !formData.jobDestinationLatitude) return;

    this.sharedService.updateRequestRideData({
      requestRide: {
        origin: formData.jobOriginLatitude &&  {
          lat: formData.jobOriginLatitude,
          lng: formData.jobOriginLongitude
        },
        destination:  formData.jobDestinationLatitude && {
          lat: formData.jobDestinationLatitude,
          lng: formData.jobDestinationLongitude
        }
      }
    });

    const payload = {
      vehicles:
        this.fleetProfile && this.fleetProfile.vehicle
          ? [this.fleetProfile.vehicle._id]
          : _.map(this.vehicles, "_id"),
      companyId: environment.COMPANY_ID,
      locations: [
        {
          address: formData.jobOriginAddress,
          latitude: formData.jobOriginLatitude,
          longitude: formData.jobOriginLongitude,
        },
        {
          address: formData.jobDestinationAddress,
          latitude: formData.jobDestinationLatitude,
          longitude: formData.jobDestinationLongitude,
        },
      ],
    };

    this.mainService
      .calculateFareEstimation(payload)
      .subscribe((reponse: any) => {
        this.fareResponce = reponse.fareResponse;

        // this.sharedService.updateRequestRideData({
        //   fleetProfile: this.fleetProfile,
        //   trip: this.requestForm.value,
        //   fare: reponse.fareResponse[0],
        // });
        // this.router.navigate(["requestaride"]);
      });
  }

  onSubmit() {
    this.submitted = true;
    if (!this.requestForm.invalid) {
      this.onRequestRide();
      const formData = this.requestForm.value;
      // const payload = {
      //   vehicles: [this.fleetProfile.vehicle._id],
      //   locations: [
      //     {
      //       address: formData.jobOriginAddress,
      //       latitude: formData.jobOriginLatitude,
      //       longitude: formData.jobOriginLongitude,
      //     },
      //     {
      //       address: formData.jobDestinationAddress,
      //       latitude: formData.jobDestinationLatitude,
      //       longitude: formData.jobDestinationLongitude,
      //     },
      //   ],
      // };

      // this.mainService
      //   .calculateFareEstimation(payload)
      //   .subscribe((reponse: any) => {
      //     console.log("Fare Estimation ", reponse);

      //     this.sharedService.updateRequestRideData({
      //       fleetProfile: this.fleetProfile,
      //       trip: this.requestForm.value,
      //       fare: reponse.fareResponse[0],
      //     });
      //     this.router.navigate(["requestaride"]);
      //   });
    }
  }

  onSubmitUserForm() {
    console.log("form Values", this.userForm.value);
    this.userFormSubmitted = true;
    if (!this.userForm.invalid) {
      this.isConnecting = true;
      this.mainService
        .createPassenger(this.userForm.value)
        .subscribe((reponse: any) => {
          console.log("Passenger Data ", reponse);
          this.socket.disconnectSocket();
          this.socket.setToken(reponse.token);
          this.socket.connectSocket();
          this.mainObject = {
            fleetProfile: this.fleetProfile,
            trip: this.requestForm.value,
            fare: this.fareResponce,
            passenger: reponse,
          };
          this.sharedService.updateRequestRideData(this.mainObject);

          this.createTripRequest();
        });
    }
  }
  goToHome() {
    // this.sharedService.clearData();
    this.router.navigate(["/qrscanner"]);
  }
  async createTripRequest() {
    if (
      this.mainObject &&
      this.mainObject.fleetProfile &&
      this.mainObject.fleetProfile.company.timeZone
    ) {
      this.timeZone = this.mainObject.fleetProfile.company.timeZone;
    }
    let payload;
    payload = {
      scheduleTime: moment().tz(this.timeZone).toISOString(),
      fare: this.selectedVehicle
        ? this.getVehicleFare(this.selectedVehicle)
        : this.fareResponce[0],
      passengers: this.requestForm.value.jobPassengerNumber,
      locations: [
        {
          address: this.requestForm.value.jobOriginAddress,
          latitude: this.requestForm.value.jobOriginLatitude,
          longitude: this.requestForm.value.jobOriginLongitude,
        },
        {
          address: this.requestForm.value.jobDestinationAddress,
          latitude: this.requestForm.value.jobDestinationLatitude,
          longitude: this.requestForm.value.jobDestinationLongitude,
        },
      ],
      vehicle:
        this.mainObject && this.mainObject.fleetProfile
          ? this.mainObject.fleetProfile.vehicle._id
          : this.selectedVehicle,

      latitude: this.requestForm.value.jobOriginLatitude,
      longitude: this.requestForm.value.jobOriginLongitude,
      company: environment.COMPANY_ID,
      createdBy: 'qr-portal',
      userType: "passenger",
    };


    //check zone of trip pickup
    const allZones = await this.zonesService.getZones().toPromise();
    if (allZones && allZones.length) {

      let point = new google.maps.LatLng(payload.locations[0].latitude, payload.locations[0].longitude);

      for (let item of allZones) {
        const zone = [];
        // for (let item2 of item.geo.coordinates[0]) 
        // const coordinates = [...item['geo']['coordinates'][0]];

        for (let item2 of item.geo.coordinates[0]) {
          zone.push(new google.maps.LatLng(item2[1], item2[0]));
        }

        const polygon = new google.maps.Polygon({ paths: zone });
        const resultPath = google.maps.geometry.poly.containsLocation(
          point,
          polygon
        )

        if (resultPath) {
          payload = { ...payload, zone: item['_id'] }
          break;
        }

      }

    }




    if (this.mainObject && this.mainObject.fleetProfile) {
      payload = {
        ...payload,
        requestedFleet: this.mainObject.fleetProfile._id,
      };
    }
    console.log("response payload ", payload);
    this.socket.emit(Events.CREATE_RIDE, payload);
  }
  onRequestRide() {
    this.isModalOpened = true;
  }
  onCancelModal() {
    this.isModalOpened = false;
    this.userFormSubmitted = false;
  }
  onSelectVehicle(vehicleId) {
    this.selectedVehicle = vehicleId;
    // this.formGroup.patchValue({
    //   vehicle: vehicleId,
    //   fleet: null,
    // });
  }
  getVehicles() {
    // const payload = {
    //   status: "active",
    // };
    // type: cab | nemt
    const payload = {
      status: "active",
      type: "cab",
      companyId: environment.COMPANY_ID
    };

    this.mainService.getVehicles(payload).subscribe(
      (data) => {
        if (data) {
          this.vehicles = data;
          this.selectedVehicle = data[0]._id;
        }
      },
      (err) => { }
    );
  }

  getVehicleFare(id) {
    return this.fareResponce.find((data) => data.vehicle === id);
  }
}
