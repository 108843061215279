import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MainService } from "src/app/services/main-service/main.service";
import { SharedService } from "src/app/services/shared-service/shared-service";
import { SocketService } from "src/app/services/socket-service/socket-service.service";

@Component({
  selector: "app-taxistand",
  templateUrl: "./taxistand.component.html",
  styleUrls: ["./taxistand.component.css"],
})
export class TaxistandComponent implements OnInit {
  mainObject = {
    taxiStand: {},
  };
  pickUpLatlng;

  constructor(
    private sharedService: SharedService,
    private mainService: MainService,
    private socket: SocketService,
    private router: Router
  ) {}

  ngOnInit() {
    this.sharedService.getPosition().then((pos) => {
      console.log(`Positon: ${pos.lng} ${pos.lat}`);
            this.mainObject.taxiStand = {        
              ...this.mainObject.taxiStand,   
               origin: {
                  lat: pos.lat,
                  lng: pos.lng,
                },
                

          //     origin: {
          //   lat: 31.520862,
          //   lng: 74.410101,
          // },
            };

            this.sharedService.updateRequestRideData({
              ...this.mainObject,
              isTaxiStand: true,
            });
    });

    const url = this.router.url.split("/");
    // console.log(url);
    if (url.length === 3) {
      if (url[2]) {
        this.pickUpLatlng = url[2].split(",");
        // console.log("latlng", this.pickUpLatlng);

        this.mainObject.taxiStand = {
          // origin: {
          //   lat: 31.520862,
          //   lng: 74.410101,
          // },

          ...this.mainObject.taxiStand,
          destination: {
            lat: parseFloat(this.pickUpLatlng[0]),
            lng: parseFloat(this.pickUpLatlng[1]),
          },
        };
        console.log("taxiStand", this.pickUpLatlng);
        this.sharedService.updateRequestRideData({
          ...this.mainObject,
          isTaxiStand: true,
        });
      }
    }
  }

  goToHome() {
    this.sharedService.clearData();
    this.router.navigate(["**"]);
  }
  ngOnDestroy() {}
}
