import { InvoiceRideComponent } from "./components/invoice-ride/invoice-ride.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { EnrouteRideComponent } from "./components/enroute-ride/enroute-ride.component";
import { HomeComponent } from "./components/home/home.component";
import { RequestRideComponent } from "./components/request-ride/request-ride.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { QrcodeScannerComponent } from "./components/qrcode-scanner/qrcode-scanner.component";
import { TrackingComponent } from "./components/tracking/tracking.component";
import { TaxistandComponent } from "./components/taxistand/taxistand.component";
import { TripReceiptComponent } from "./components/trip-receipt/trip-receipt.component";

const routes: Routes = [
  
 
  {
    path: "",
    component: HomeComponent,
  },
  {
    path: "home/:id",
    component: HomeComponent,
  },
  {
    path: "qrscanner",
    component: QrcodeScannerComponent,
  },
  {
    path: "requestaride",
    component: RequestRideComponent,
  },
  {
    path: "enrouteride/:id",
    component: EnrouteRideComponent,
  },
  {
    path: "invoice/:id",
    component: InvoiceRideComponent,
  },
  {
    path: "tracking/:passengerId/ride/:rideId",
    component: TrackingComponent,
  },
  {
    path: "taxistand/:latlng",
    component: TaxistandComponent,
  },
  {
    path: "tripreceipt/:id",
    component: TripReceiptComponent,
  },

  { path: "**", redirectTo: "/404" },
  { path: "404", component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
