import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import * as moment from "moment-timezone";
import { ToastrService } from "ngx-toastr";
import { MainService } from "src/app/services/main-service/main.service";
import { SharedService } from "src/app/services/shared-service/shared-service";
import { SocketService } from "src/app/services/socket-service/socket-service.service";
import { Events } from "src/app/utils/event-utils";

@Component({
  selector: "app-enroute-ride",
  templateUrl: "./enroute-ride.component.html",
  styleUrls: ["./enroute-ride.component.css"],
})
export class EnrouteRideComponent implements OnInit, OnDestroy {
  mainObject;
  intervalClock = null;
  driverWaitingTimeClock = null;
  clockTimer = null;
  tripId;
  isRideInfoOpened = false;
  isContactOpened = false;
  isOpenToll = false;
  googleFare;
  isCancelledConfirm = false;
  timeFormater = moment;
  driverWaitingTime;
  showWaitTimer;
  isDriverWaitimeExpire;

  constructor(
    private sharedService: SharedService,
    private mainService: MainService,
    private socket: SocketService,
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit() {

    const url = this.router.url.split("/");
    if (url.length === 3) {
      this.tripId = url[2];
      this.getRideDetails();
    }

    this.sharedService.getRequestRideData().subscribe((data) => {
      this.mainObject = data;
      if (data === null) {
        // this.sharedService.clearData();
        this.router.navigate(["/"]);
      }
    });

    this.socket.addListener(Events.UPDATE_RIDE_INFO).subscribe((data) => {
      // if (data && data.distance) {
      //   this.googleFare = data.distance;
      // }
      if (data.ride) {
        this.mainObject.ride.status = data.ride.status;
      }


    });
    this.socket.addListener(Events.GOOGLE_ESTIMATION).subscribe((data) => {
      if (data && data.distance) {
        this.googleFare = data.distance;
      }
    });
    this.socket.addListener(Events.DRIVER_NOTIFICATIONS).subscribe((data) => {
      if (data.message) {
        // this.sharedService.clearData();
        this.router.navigate(["/"]);
      } else {
        console.log(Events.DRIVER_NOTIFICATIONS, data);

        if (data.type === "farefeed") {
          this.sharedService.updateRequestRideData({
            ...this.mainObject,
            farefeed: data.fare,
          });
        } else if (data.type === "tolladded") {
          this.toastr.success('Your fare has been updated by driver.', 'Toll');
          this.sharedService.updateRequestRideData({
            ...this.mainObject,
            farefeed: data.fare,
          });
        } else {
          if (data.ride) {
            if (data.ride.driver && !this.mainObject.ride.driver) {
              this.mainObject.ride.driver = data.ride.driver;
              this.sharedService.updateRequestRideData({
                ...this.mainObject,
              });
            }
            if (this.mainObject.ride.status !== data.ride.status) {
              this.mainObject.ride.status = data.ride.status;
              this.sharedService.updateRequestRideData({
                ...this.mainObject,
              });

              if (this.mainObject.ride.status === "arrived") {
                this.getRideDetails();
              } else {
                if (this.driverWaitingTimeClock) {
                  clearInterval(this.driverWaitingTimeClock);
                }
              }

            }
            if(!this.mainObject.fleet){
            if (this.mainObject && this.mainObject.ride && this.mainObject.ride.fleet) {
              this.getFleetInfo(this.mainObject.ride.fleet);
            }else if (this.mainObject && this.mainObject.fleetProfile) {
              this.getFleetInfo(this.mainObject.fleetProfile._id);
            }
          }

            if (["completed", "finished"].includes(data.ride.status)) {
              this.stopClockTimer();
              //  we have here ride Id insted of _id
              this.router.navigate(["invoice/" + data.ride.rideId]);
            }
          }
        }
      }
    });

    this.socket.addListener(Events.GET_RIDE_INFO).subscribe((data) => {
      if (data.message) {
        // this.sharedService.clearData();
        this.router.navigate(["/"]);
      } else {
        console.log(Events.GET_RIDE_INFO, data);
        
        this.sharedService.updateRequestRideData({
          ...this.mainObject,
          ride: data.ride,
          events: data.events
        });

        if (this.mainObject.ride.status === "arrived" && this.mainObject.events) {
          const driverWaitingTime = this.mainObject.events.find(item => item.status === "arrived")
          this.startDriverWaitime(driverWaitingTime);
        } else {
          if (this.driverWaitingTimeClock) {
            clearInterval(this.driverWaitingTimeClock);
          }
        }

        if (this.mainObject && this.mainObject.ride && this.mainObject.ride.fleet && !this.mainObject.fleet) {
          this.getFleetInfo(this.mainObject.ride.fleet);
        }


        if (["completed", "finished"].includes(data.ride.status)) {
          this.stopClockTimer();
          //  we have here ride Id insted of _id
          this.router.navigate(["invoice/" + data.ride.rideId]);
        }
      }
    });
  }

  getRideDetails() {
    this.socket.emit(Events.GET_RIDE_INFO, { rideId: this.tripId });
  }

  openContactInfo() {
    this.isContactOpened = !this.isContactOpened
    if (this.isRideInfoOpened)
      this.isRideInfoOpened = false;
  }

  fetchGoogleETA() {
    this.isRideInfoOpened = !this.isRideInfoOpened;
    if (this.isContactOpened)
      this.isContactOpened = false;
    
    if (this.googleFare) return;

    if (
      this.mainObject &&
      this.mainObject.ride &&
      this.mainObject.ride.locations[0]
    ) {
      this.socket.emit(Events.GOOGLE_ESTIMATION, {
        pickup: `${this.mainObject.ride.locations[0].latitude},${this.mainObject.ride.locations[0].longitude}`,
        dropOff: `${this.mainObject.ride.locations[1].latitude},${this.mainObject.ride.locations[1].longitude}`,
      });
    } else {
      console.log("Payload null");
    }
  }

  startClockTimer(jobData) {
    this.intervalClock = setInterval(() => {
      let duration = moment.duration(moment().diff(moment(jobData.time)));
      this.clockTimer = moment()
        .set({
          hour: duration._data.hours,
          minute: duration._data.minutes,
          seconds: duration._data.seconds,
        })
        .format("HH:mm:ss");
    }, 1000);
  }

  stopClockTimer() {
    if (this.intervalClock) {
      clearInterval(this.intervalClock);
    }
  }

  shareTrip(ride) {
    this.sharedService.share(ride);
  }

  ngOnDestroy() {
    this.stopClockTimer();
    if (this.driverWaitingTimeClock) {
      clearInterval(this.driverWaitingTimeClock);
    }
  }
  goToHome() {
    this.sharedService.clearData();
    this.router.navigate(["/"]);
  }

  cancelTrip() {
    this.socket.emit(Events.UPDATE_RIDE_INFO, {
      rideId: this.mainObject.ride._id,
      status: 'cancelled', // ['hanging', 'onway', 'arrived', 'started', 'completed', 'finished', 'cancelled']
      latitude:
        this.mainObject && this.mainObject.ride && this.mainObject.ride.driver
          ? this.mainObject.ride.driver.latitude
          : this.mainObject.ride.locations[0].latitude, // current
      longitude:
        this.mainObject && this.mainObject.ride && this.mainObject.ride.driver
          ? this.mainObject.ride.driver.longitude
          : this.mainObject.ride.locations[0].longitude, // current
      userType: "passenger", // passenger or driver
    });
    this.isCancelledConfirm = false;
  }

  searchAgain() {

    let payload;
    payload = {
      rideId: this.mainObject.ride._id,
    };
    if (this.mainObject.fleetProfile && this.mainObject.fleetProfile._id) {
      payload = {
        ...payload,
        requestedFleet: this.mainObject.fleetProfile._id,
      };
    }

    this.socket.emit(Events.SEARCH_AGAIN, payload);
  }

  getFleetInfo(fleetId) {
    // console.log("getFleetInfo", fleetId)
    this.mainService
      .fetchFleet(fleetId)
      .subscribe((data) => {
        if (data) {
          this.mainObject.fleet = data;
        }
      });
  }




  startDriverWaitime(driverWaitingTime) {

    this.driverWaitingTimeClock = setInterval(() => {

      const arrivedTime = driverWaitingTime.createdAt;
      let startTime = moment(arrivedTime);
      let endTime = moment();
      let duration = moment.duration(endTime.diff(startTime));
      let timeDifference = moment(`${duration.hours()}:${duration.minutes()}:${duration.seconds()}`, "HH:mm:ss")
      if (duration.minutes() < 3) {
        // postive 
        // first check the difference between 3 mins 
        // run the countdown of that timeDifference
        this.isDriverWaitimeExpire = false;

        const newTime = moment(arrivedTime).add("minutes", 3);
        let durationNew = moment.duration(newTime.diff(moment()));
        timeDifference = moment(`${durationNew.hours()}:${durationNew.minutes()}:${durationNew.seconds()}`, "HH:mm:ss")

      } else {
        // nagitave
        this.isDriverWaitimeExpire = true;
        timeDifference.subtract("minutes", 3);
      }
      this.showWaitTimer = timeDifference.format('HH:mm:ss');

    }, 1000);
  }
}
