export enum Events {
    CONNECTION_TEST= 'chatMessage',
    CREATE_RIDE= 'createRide',
    SEARCH_AGAIN= 'searchAgain',
    GET_RIDE_INFO= 'getRideInfo',
    GET_RIDE_INFO_QR= 'getRideInfoQR',
    DRIVER_NOTIFICATIONS= 'driverNotifications',
    UPDATE_FLEET_LOCATION= 'updateFleetLocation',
    GET_RIDE_INVOICE= 'getRideInvoice',
    GOOGLE_ESTIMATION= 'googleEstimation',
    UPDATE_RIDE_INFO= 'updateRideInfo',
    RIDE_RATING= 'rideRating',
    ADD_RIDE_TIP= 'addRideTip',
}
