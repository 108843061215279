import { catchError } from "rxjs/operators";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import * as moment from "moment";
import { MainService } from "src/app/services/main-service/main.service";
import { SharedService } from "src/app/services/shared-service/shared-service";
import { SocketService } from "src/app/services/socket-service/socket-service.service";
import { Events } from "src/app/utils/event-utils";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-invoice-ride",
  templateUrl: "./invoice-ride.component.html",
  styleUrls: ["./invoice-ride.component.css"],
})
export class InvoiceRideComponent implements OnInit, OnDestroy {
  mainObject;
  intervalClock = null;
  clockTimer = null;
  tripId;
  interval = null;
  selectedTrip = "";
  isShowThanksModal = false;
  isShowTipConfirmationModal = false;
  howWasDriver = 5;
  howWasBehavior = 5;
  howWasCleanliness = 5;
  review = "";
  calculatedPercentages;
  selectedTip;
  isTipView;


  constructor(
    private sharedService: SharedService,
    private mainService: MainService,
    private router: Router,
    private socket: SocketService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.sharedService.getRequestRideData().subscribe((data) => {
      this.mainObject = data;
      console.log("common data: ", this.mainObject);
      if (data === null) {
        // this.sharedService.clearData();
        this.router.navigate(["/"]);
      }
    });

    this.socket.addListener(Events.GET_RIDE_INVOICE).subscribe((data) => {
      if (data.message) {
        // this.sharedService.clearData();
        this.router.navigate(["/"]);
      } else {
        console.log(Events.GET_RIDE_INVOICE, data);
        this.sharedService.updateRequestRideData({
          ...this.mainObject,
          invoice: data,
        });

        this.calculateTip(data);
      }
    });

    this.socket.addListener(Events.RIDE_RATING).subscribe((data) => {
      if (data.rating) {
        this.isShowThanksModal = true;
      }
    });

    this.socket.addListener(Events.DRIVER_NOTIFICATIONS).subscribe((data) => {
      if (data.message) {
        // this.sharedService.clearData();
        this.router.navigate(["/"]);
      } else {
        if (data.type === "farefeed" || data.type === "tolladded") return;
        this.mainObject.ride.status = data.ride.status;
        this.sharedService.updateRequestRideData({
          ...this.mainObject,
        });
      }
    });

    this.socket.addListener(Events.GET_RIDE_INFO).subscribe((data) => {
      console.log(data);
      if (data.message) {
        // this.sharedService.clearData();
        this.router.navigate(["/"]);
      } else {
        this.socket.emit(Events.GET_RIDE_INVOICE, { rideId: this.tripId });
        this.sharedService.updateRequestRideData({
          ...this.mainObject,
          ride: data.ride,
        });
      }
    });

    const url = this.router.url.split("/");
    if (url.length === 3) {
      this.tripId = url[2];
    }
    this.getInvoiceData();
  }

  getInvoiceData() {
    this.socket.emit(Events.GET_RIDE_INFO, { rideId: this.tripId });

    // this.mainService.fetchInvoiceData(this.jobId).subscribe(
    //   (data: any) => {
    //     if (data.jobStatus === "completed" || data.jobStatus === "finished") {
    //       console.log("getInvoiceData: ", data);
    //       this.mainObject.trip = data;
    //       this.sharedService.updateRequestRideData(this.mainObject);
    //       if (data.jobStatus === "finished") {
    //       }
    //     } else {
    //       // this.sharedService.clearData();
    //       this.router.navigate(["/"]);
    //     }
    //   },
    //   (error) => {
    //     // this.sharedService.clearData();
    //     this.router.navigate(["/"]);
    //   }
    // );
  }

  postReview() {
    console.log(
      "=======> ",
      this.review,
      (this.howWasDriver + this.howWasBehavior + this.howWasCleanliness) / 3
    );

    this.socket.emit(Events.RIDE_RATING, {
      userType: "passenger",
      behaviour: this.howWasBehavior,
      service: this.howWasDriver,
      cleanliness: this.howWasCleanliness,
      feedback: this.review,
      rating:
        (this.howWasDriver + this.howWasBehavior + this.howWasCleanliness) / 3,
      driver: this.mainObject.ride.driver._id,
      ride: this.mainObject.ride._id,
    });
  }

  goToHome() {
    this.sharedService.clearData();
    this.router.navigate(["/"]);

  }
  setTipValue(tip) {
    this.selectedTrip = tip;
    console.log(this.selectedTrip === tip);
  }
  ngOnDestroy() { }

  calculateTip(data) {
    const total = data.breakDown[0].value;
    const checkTip = data.breakDown.find(data => data.key === "Tip");
    if (checkTip) return;
    this.calculatedPercentages = {
      two: (2 / 100) * total,
      five: (5 / 100) * total,
      ten: (10 / 100) * total
    }
  }

  setTip() {
    if (!this.selectedTip || this.selectedTip <= 0) {
      this.selectedTip = "0";
      return;
    }
    this.isTipView = false;
    this.isShowTipConfirmationModal = true;
  }

  unSelectTip() {
    this.isTipView = false;
    this.isShowTipConfirmationModal = false;
    this.selectedTip = null;
  }

  updateTip() {
    this.socket.emit(Events.ADD_RIDE_TIP, {
      rideId: this.mainObject.ride._id,
      driver: this.mainObject.ride.driver,
      tip: this.selectedTip
    });
    this.isShowTipConfirmationModal = false;
    this.toastr.success('You have paid tip to driver!.', 'Tip');
  }

}
