import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SocketService } from '../socket-service/socket-service.service';
const navigator = window.navigator as any;
@Injectable({
  providedIn: 'root'
})
export class SharedService {
  
  private requestRideData = new BehaviorSubject(null);

  constructor(private socket: SocketService) { }

  getRequestRideData() {
   const payloadData =  localStorage.getItem('trip')
    if(payloadData){
      // this.requestRideData = new BehaviorSubject(null);
      this.requestRideData.next(JSON.parse(payloadData))
    }
    
    return this.requestRideData;
  }

  updateRequestRideData(data) {
    if(data && !data.isTracking && !data.isTaxiStand && !data.requestRide){
      localStorage.setItem('trip', JSON.stringify(data));
    }
    this.requestRideData.next(data);
  }

  clearData(){
    this.socket.disconnectSocket()
    localStorage.removeItem('trip')
    localStorage.removeItem('token')
    this.requestRideData.next(null);
  }


  getPosition(): Promise<any>
  {
    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(resp => {

          resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
        },
        err => {
          reject(err);
        });
    });

  }

  share(
    ride
  ) {

console.log(ride);
const payload  = {
  title: "Share Info",
  text: `
Hi,

I am riding with the driver ${ride.driver.displayName} of United Taxi. I am going from ${ride.locations[0].address} to ${ride.locations[1].address}. 

Thanks
`,
  url:`${ environment.QR_PANEL_URL}/tracking/${ride.passenger}/ride/${ride._id}`,
};

// console.log('payload ===> ',payload)
    if (navigator.share) {
      navigator
        .share(payload)
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error));
    } else {
      alert("share not supported");
    }
  }

}
