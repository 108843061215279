import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { AgmOverlays } from "agm-overlays";
import { NgHttpLoaderModule } from 'ng-http-loader';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { GooglePlaceDirective } from './directives/google-places-directive/google-place.directive';
import { HomeComponent } from './components/home/home.component';
import { RequestRideComponent } from './components/request-ride/request-ride.component';
import { EnrouteRideComponent } from './components/enroute-ride/enroute-ride.component';
import { InvoiceRideComponent } from './components/invoice-ride/invoice-ride.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { QrcodeScannerComponent } from './components/qrcode-scanner/qrcode-scanner.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { SocketIoModule, SocketIoConfig, Socket } from 'ngx-socket-io';
import { SocketService } from './services/socket-service/socket-service.service';
import { TrackingComponent } from './components/tracking/tracking.component';
import { NgxStarsModule } from 'ngx-stars';
import { ToastrModule } from 'ngx-toastr';
import { TaxistandComponent } from './components/taxistand/taxistand.component';
import { TripReceiptComponent } from './components/trip-receipt/trip-receipt.component';
import { TimezonePipe } from './pipes/timezone-pipe/timezone.pipe';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    GooglePlaceDirective,
    RequestRideComponent,
    EnrouteRideComponent,
    InvoiceRideComponent,
    NotFoundComponent,
    QrcodeScannerComponent,
    TrackingComponent,
    TaxistandComponent,
    TripReceiptComponent,
    TimezonePipe
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    AgmOverlays,
    NgxStarsModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    AppRoutingModule,
    SocketIoModule,
    NgHttpLoaderModule.forRoot(),
    NgxSmartModalModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAhQKWoOEKCQWCuExvFPTGsnql9Y3-NFBA',
      libraries: ["places","geometry"]
    }),
    AgmDirectionModule,
    Ng2SearchPipeModule,
    ZXingScannerModule,
    ToastrModule.forRoot(),
  ],
  providers: [GoogleMapsAPIWrapper,SocketService],
  bootstrap: [AppComponent]
})
export class AppModule { }
